/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import { useI18n } from '@bestyled/primitives'
import _ from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'

import mediaqueries from '../../styles/media'
import Mdx from '../../components/MDX'
import { useAppContext } from '../../utils/global-context'

export function LandingFAQ({ ...props }) {
  const results = useStaticQuery(graphql`
    {
      allMdx(
        sort: { fields: frontmatter___menu, order: ASC }
        filter: { frontmatter: { type: { regex: "/landing/gi" } } }
      ) {
        edges {
          node {
            fileAbsolutePath
            childrenComponentInMdx {
              component
            }
            body
            frontmatter {
              title
              lang
              content {
                question
                answer
              }
              date
            }
          }
        }
      }
    }
  `)
  const { locale, isCompatible } = useI18n()
  const [appContextState, setContext] = useAppContext()
  const [menuTitle = appContextState.navItems?.faqs, _setTitle] = React.useState()
  const setTitle = _.once(_setTitle)
  function cleanup() {
    setContext((prev) => {
      delete prev.navItems?.faqs
      return prev
    })
  }
  const contextTitle = _.once((menuTitle: any) => {
    return (prev) => {
      let navItems = prev.navItems
      if (!navItems.extras)
        navItems.extras = []
      navItems.extras.push(menuTitle)
      return { ...prev, navItems }
    }
  })

  const addExtraTextToCtx = (menuTitle) => {
    if (!appContextState.navItems || appContextState.navItems.faqs === menuTitle) return
    if (
      appContextState.navItems?.extras &&
      _.isArray(appContextState.navItems.extras) &&
      appContextState.extras.find(t => menuTitle === t)
    )
      return
    setContext(contextTitle(menuTitle))
  }
  React.useEffect(() => {
    if (!appContextState.navItems) appContextState.navItems = {}
    if (!appContextState.navItems.faqs || appContextState.navItems.faqs !== menuTitle)
      setContext((prev) => ({ ...prev, navItems: { ...prev.navItems, faqs: menuTitle } }))

    return cleanup
  }, [menuTitle])

  return (
    <section className='container' id='faq-section'>
      {results.allMdx.edges
        .filter((node) => {
          return (
            new RegExp(locale.toLowerCase(), 'gi').test(node.node?.frontmatter?.lang) ||
            isCompatible(node.node?.frontmatter?.lang)
          )
        })
        .map((node, index) => {
          let {
            body,
            frontmatter: { title = 'Faq' }
          } = node.node
          if (!_.isEmpty(title) && title !== menuTitle && index === 0) setTitle(title)
          else if (!_.isEmpty(title) && title !== menuTitle && !appContextState.navItems?.extras ) addExtraTextToCtx(title)
            return (
              <FAQSection key={index} className='container' id={`${title}-section-part`}>
                <FAQHeader className='row'>
                  <FAQTitle
                    className='col-12'
                    dangerouslySetInnerHTML={{ __html: title }}
                    id={_.isString(title) ? _.kebabCase(title) : 'faq-title'}
                  />
                </FAQHeader>
                <Mdx content={body} node={node.node} />
              </FAQSection>
            )
        })}
    </section>
  )


}

const FAQSection = styled.section`
  z-index: 5;

  * {
    z-index: 5;
  }
  .row {
    @media only screen and (min-width: 60em) {
      width: 100%;
      margin-left: 5%;
    }
  }

  .row [class^='col'] {
    @media only screen and (min-width: 60em) {
      margin: 15px 1.2% 0 !important;
    }
  }
`

const FAQHeader = styled.header`
  font-family: ${(p) => p.theme.fonts.header};
  font-size: ${(p) => p.theme.fonts.sizes[7]}px;
  font-weight: 400;
  line-height: 1.9;
  color: #757575;
  box-sizing: border-box;
  display: block;
  text-align: center;
  letter-spacing: 0.5px;
  margin: 0 auto 1vh;
`

const FAQTitle = styled.h1`
  font-family: ${(p) => p.theme.fonts.header};
  font-size: ${(p) => p.theme.fontSizes[9]}px !important;
  text-align: center;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Dosis, sans-serif;
  line-height: 1;
  font-weight: 400;
  color: ${(p) => p.theme.colors.primary};
  letter-spacing: 0.5px;
  font-weight: 400;
`

const FAQSubHeading = styled.h4`
  font-family: ${(p) => p.theme.fonts.paragraph};

  line-height: 1.9;
  color: #757575;
  text-align: center;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 1.3em;
  font-weight: 300;
  font-size: ${(p) => p.theme.fontSizes[3]}px;
`
